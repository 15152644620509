import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import Stripe5 from './Stripe5';
import GetEventSeatsInfo from './GetEventSeatsInfo';
import ChangeSeatStatus from './ChangeSeatStatus'
import PurchasePreview from './PurchasePreview'

function EventTicketing() {

    const [event, setEvent] = useState({eventName: '',dateTime: ''});
    const [rows, setRows] = useState([]);
    const [reserved, setReserved] = useState([]);
    const [seats, setSeats] = useState({});
    const [seatDetails, setSeatDetails] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sessionUserName, setSessionUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [reload, setReload] = useState(0);
    const [SKRoot, setSKRoot] = useState('2021-04-05-20-30');
    const [PK, setPK] = useState('123');
    const [loadEvent, setLoadEvent] = useState('event-details-' + SKRoot);
    const [promoCode, setPromoCode] = useState('');
    const [donation, setDonation] = useState(0.00);
    const [customerCredit, setCustomerCredit] = useState(50.00);
    const [applyCustomerCredit, setApplyCustomerCredit] = useState(0.00);

    function CharacterDropDown() {
        const [eventsList, setEventsList] = useState([]);
        async function getEventDetailsByPK(){
            let myInit = {
                body: { 
                    "Action": "getEventsDetailByPK",         
                    "PK": '123',
                    "SK": 'event-details' 
                    },
                headers: {} // OPTIONAL
            }
            const response = await API.post ('ticket57api', '/events', myInit)
            console.log("RESPONSE1", response)
            setEventsList(response)
        }
        useEffect(() => {
            getEventDetailsByPK()
        },[])

        return (
          <select onChange={event => {setSKRoot(event.target.value); setLoadEvent('event-details-' + event.target.value)}}>
            {eventsList.map( event => {
                return(
                    <option value={event.SK.replace("event-details-", "")}>{event.dateTime} ... {event.eventName} </option>
                )
            })}
          </select>
        );
    }

    function chgClassName(seat, status, reserved){
        if(reserved.indexOf(seat) > -1){
            return 'reserved';
        }else if (seat == 'ns') {
            return 'ns';
        }else if( seat in seats){
            return seats[seat];
        } else {
            return status;
        }
    }

    function onClickData(seat, reserved, setReserved) {
        if((seats[seat] !== 'sold') && (seats[seat] !== 'ns' )&& (seats[seat] !== 'reserved2')){
            if(reserved.indexOf(seat) > -1 ) {
                setReserved([])
                setSeatDetails({})
                console.log("SPOT5:", reserved)
            }else{
                setReserved([...reserved, seat]);
                setSeatDetails({...seatDetails, [seat]: {seatID: seat, seatPrice: event.eventSeatPriceAdult, seatPriceCode: "Adult"}})
                console.log("SPOT6:", reserved)
            }
        }
        console.log(seat)
        console.log("RESERVED", reserved)
    }

    function Refresh(){
        if(reload == 0){
            setReload(1);
        }else{
            setReload(0)
        }
    }

    async function ReserveSeatsInDB(seatStatus, firstName, lastName){
        reserved.map( rseat => {
            console.log("RSEAT", rseat)
            console.log("FIRSTNAME", firstName)
            let SKey = 'event-' + SKRoot + '-seat-' + rseat
            ChangeSeatStatus(rseat, seatStatus, PK, SKey, firstName, lastName, sessionUserName);
        })
        setReserved([])
    }

    async function EventSeatChart() {
        setRows([])
        console.log("SKRoot",SKRoot)
        console.log("loadEvent",loadEvent)
        await Auth.currentSession()
            .then(data => console.log("AUTHDATA",data.accessToken.payload.username))
            .catch(err => console.log("AUTHERRORDATA",err));
        const testuser = await Auth.currentAuthenticatedUser()
        console.log("TESTUSER",testuser.username)
        setSessionUserName(testuser.username)   
        let myInit = {
            body:{
                "Action": "getEventSeatChart",
                "SK": loadEvent,
                "PK": PK
            }
        }
        const apiData = await API.post ('ticket57api', '/events', myInit)
        let response = await GetEventSeatsInfo(PK, 'event-' + SKRoot + '-seat')
        let seatInfo = {};
        response.map( seat => {
            console.log("SEATS11",seat)
            seatInfo[seat.seatID] = seat.seatStatus
            console.log("SEATINFO",seatInfo)
        })
        setSeats(seatInfo)
        console.log("APIDATA",apiData)
        console.log("SEATS", seats)
        setEvent(apiData[0]);
        setRows(apiData[0].rows);
    }

    useEffect(() => {
        EventSeatChart()
    },[reload, loadEvent, SKRoot])
    
    let counter = 0

    return (
    <div className="App">
        <header className="App-header">
        <div className="seatTable"> 
        {CharacterDropDown()}
        <h1>{event.eventName}</h1>
        <h1>{event.dateTime}</h1>   
        <div className="seatTable">
        <table className="grid">    
        <tbody>             
              {rows.map( row => {
                  counter += 1
                  return <tr id={row.rowId + counter.toString()} key={row.rowId}>
                  {row.rowMap.map( seat => { 
                  counter += 1    
                  //return <td id={seat}className="available"> {seat}
                  return <td id={seat + counter.toString()} className={chgClassName(seat, "available", reserved)} key={seat} onClick = {e => {onClickData(seat, reserved, setReserved)}}>
                      {seat}
                  </td>
                  })}              
                  </tr>
                })}              
          </tbody>
        </table>
        <div className="stage">STAGE</div>       
        </div>
        <div className="seatinfo">
            <p>Session UserName: {sessionUserName}</p>
        <table> 
        <tbody>     
        <tr><td>Customer First Name:</td><td><input className='purchasePreviewInput' type="text" value={firstName} onChange={event => setFirstName(event.target.value)}/></td></tr>
        <tr><td>Customer Last Name:</td><td><input className='purchasePreviewInput' type="text" value={lastName} onChange={event => setLastName(event.target.value)}/></td></tr>
        <tr><td>Customer Email:</td><td><input className='purchasePreviewInput' type="text" value={emailAddress} onChange={event => setEmailAddress(event.target.value)}/></td></tr>
        <tr><td>Promo Code:</td><td><input className='purchasePreviewInput' type="text" value={promoCode} onChange={event => setPromoCode(event.target.value)}/></td></tr>
        <tr><td>Customer Credit:</td><td>${customerCredit}</td></tr>

        </tbody>
        </table>
        <PurchasePreview reserved={reserved} seatDetails={seatDetails} setSeatDetails={setSeatDetails} 
        event={event} firstName={firstName} lastName={lastName} emailAddress={emailAddress} 
        donation={donation} setDonation={setDonation} customerCredit={customerCredit} setCustomerCredit={setCustomerCredit}
        applyCustomerCredit={applyCustomerCredit} setApplyCustomerCredit={setApplyCustomerCredit}/>
        <p><button onClick={e => {ReserveSeatsInDB("reserved2", firstName, lastName ); Refresh()}}>Reserve Seats</button></p>
        <p><button onClick={e => {ReserveSeatsInDB("sold", firstName, lastName ); Stripe5(event.eventName + "-" + event.dateTime, emailAddress, reserved, '1234567', seatDetails, PK, SKRoot)}}>Purchase Seats</button></p> 
        </div>
        </div>
        </header>
    </div>
    );
}

export default EventTicketing