import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import Test3 from './Test3';
import ChangeSeatStatus from './ChangeSeatStatus'


function EventDetail2 (props) {

    const [event, setEvent] = useState({eventName: '',eventDate: ''});
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState({loading: false});
    const [reserved, setReserved] = useState([]);
    const [reservationName, setReservationName] = useState('');
    const [purchasePrice, setPurchasePrice] = useState(35.00);
    const [events, setEvents] = useState({});
    const [seats, setSeats] = useState({});
    const [seat, setSeat] = useState({});

    console.log('SSKK', props.sk);

    function chgClassName(seat, status, reserved){
        if(reserved.indexOf(seat) > -1){
          return 'reserved';
        }else{
          return status;
        }
    }

    function onClickData(seat, status, reserved, setReserved) {
        if (seat === 'sold') {
          alert("Seat Sold");
        }else{   
            if((status !== 'sold') && (status !== 'ns')){
                if(reserved.indexOf(seat) > -1 ) {
                    setReserved([])
                    console.log("SPOT5:", reserved)
                }else{
                    setReserved([...reserved, seat]);
                    console.log("SPOT6:", reserved)
                }    
            }
        }
        console.log(seat)
        console.log(status)
        console.log(reserved)
    }

    function getData(setSeats, setEvents, setRows, setReserved, skk) {
        setReserved([])
        let apiName = 'ticket57api';
        let path = '/items/object/123/' + skk;
        let myInit = { // OPTIONAL
            headers: {} // OPTIONAL
        }
        
        API
        .get(apiName, path, myInit)
        .then(response => {
            console.log("SPOT7")
            setSeats(response.seats);
            setEvents(response);
            setRows(response.rows);
            console.log("API2")
        })
        .catch(error => {
            console.log("SPOT3")
            console.log(error);
            console.log(error.response);
        });
    }

    function postData(customerId,eventId,reserved,status,purchasePrice,seatGuest, skk){
        let apiName = 'ticket57api';
        let path = '/items/object/123/' + skk;
        let x = 0;
        reserved.forEach( async reservedSeat => {
          let seatId = reservedSeat;
          let seatStatus = status;
          let guest = seatGuest;
          let currDateTime = getFormattedDate();
  
          let myInit = {
            body: {         
                    customerId: customerId,
                    eventId: eventId,
                    purchaseDate: currDateTime,
                    seatId: seatId,
                    seatStatus: seatStatus,
                    purchasePrice: purchasePrice,
                    seatGuest: seatGuest              
                },
            headers: {} // OPTIONAL
          }

          let response = API.post(apiName, path, myInit);
          console.log(response);
          ///do something with response
    
           Promise.resolve(response).then(function(value) {
            console.log(value);
            console.log(value.statusCode);
            getData(setSeats, setEvents, setRows, setReserved, props.sk);
    
            if(value.statusCode === 200){
              console.log("This is cool...");
            }else{
                console.log("SPOT2");
            }
          })
          .catch(function(error) {
            console.log('Request failed Mark', error);
            
          });
        })
    }

    function getFormattedDate(){
      var d = new Date();
      d = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2) + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2) + ":" + ('0' + d.getSeconds()).slice(-2);
      return d;
    }

    useEffect(() => {
        console.log('trigger use effect hook');
        console.log(loading);

        let apiName = 'ticket57api';
        let path = '/items/object/123/' + props.sk;
        let myInit = { // OPTIONAL
            headers: {} // OPTIONAL
        }
        
        API
        .get(apiName, path, myInit)
        .then(response => {
            console.log("SPOT2")
            console.log("API1")
            console.log(response)
            console.log(response.seats)
            setSeats(response.seats);
            setEvent({...event,["eventName"]: response.eventName,["eventDate"]: response.dateTime});
            setRows(response.rows);
            setEvents(response);
            setLoading(false);
            console.log("API2")
        })
        .catch(error => {
            console.log("SPOT3")
            console.log(error);
            console.log(error.response);
        });


    }, [props.sk]);
    
    const text = loading ? <div>loading...</div> : "";
    const text2 = <Test3 avail={seat.seatStatus} name={seat.guest} row={seat.rowId} arr={seat} />;
    return (
    <div className="App">
        <header className="App-header">
        <div className="seatTable">  
        {text}

        <table className="grid">
        <h1>{event.eventName}</h1>
        <h1>{event.eventDate}</h1>
          <tbody>             
              {rows.map( row => { 
                  return <tr id={row.rowId} key={row.rowId}>
                  {row.rowMap.map( seat => { 
                  return <td id={seat} className={chgClassName(seat,seats[seat].seatStatus, reserved)} key={seat} onClick = {e => {setSeat(seats[seat]); onClickData(seat,seats[seat].seatStatus, reserved, setReserved)}}> {seat}
                  </td>
                  })}              
                  </tr>
                })}              
          </tbody>
        <div class="stage">Stage</div>  
        </table>
        <table>
          <tbody>
            <tr>
              <td>Reservation Name:</td>
              <td><input
                    type="text" 
                    placeholder="Reservation Name"
                    onChange={e => setReservationName(e.target.value)}>
                  </input>
              </td>    
            </tr>
            <tr>
              <td>Purchase Price:</td>
              <td><input
                    type="number"
                    value={purchasePrice}
                    placeholder="35.00"
                    onChange={e => setPurchasePrice(e.target.value)}>
                  </input>
              </td>    
            </tr>
            <tr>
              <td></td>
              <td><button onClick={e => postData("123",props.sk,reserved,'sold',purchasePrice,reservationName, props.sk)}>Purchase Seats</button>
              </td>
            </tr>          
          </tbody>
        </table>
        {text2}
        </div>
        </header>
    </div>
    );
}

export default EventDetail2