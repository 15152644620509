import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import GetEventSeatsInfo from './GetEventSeatsInfo';
import Stripe5 from './Stripe5';

function PurchasePreview (props) {

    function chgButtonClassName(priceCode,buttonName){
        if(priceCode == buttonName){
            return 'buttonhighlight';
        }else{
            return 'nobuttonhighlight';
        }    
    }

    useEffect(() => {
        console.log("RESERVEDSEAT",props.reservedSeats)
        console.log("SEATDETAILS",props.seatDetails)
    },[props.reservedSeats])
    
    let totalSeatPrice = 0
    let initialCustomerCreditValue2 = 50
    return (
        <div>
        <table className='tablePurchasePreview'>
        <tbody> 
            <tr><td>Seat</td><td>AdmissionType</td><td>Price</td><td></td><td></td><td></td><td></td></tr>
        {props.reserved.map( seat => {
          totalSeatPrice = totalSeatPrice + props.seatDetails[seat].seatPrice
          return (<tr>
              <td>{seat}</td>
              <td>{props.seatDetails[seat].seatPriceCode}</td>
              <td>${props.seatDetails[seat].seatPrice}</td>
              <td><button className={chgButtonClassName(props.seatDetails[seat].seatPriceCode, 'Child')} onClick={e => props.setSeatDetails({...props.seatDetails, [seat]: {seatID: [seat], seatPrice: props.event.eventSeatPriceChild, seatPriceCode: "Child"}})}>Child</button></td>
              <td><button className={chgButtonClassName(props.seatDetails[seat].seatPriceCode, 'Adult')} onClick={e => props.setSeatDetails({...props.seatDetails, [seat]: {seatID: [seat], seatPrice: props.event.eventSeatPriceAdult, seatPriceCode: "Adult"}})}>Adult</button></td>
              <td><button className={chgButtonClassName(props.seatDetails[seat].seatPriceCode, 'Senior')} onClick={e => props.setSeatDetails({...props.seatDetails, [seat]: {seatID: [seat], seatPrice: props.event.eventSeatPriceSenior, seatPriceCode: "Senior"}})}>Senior</button></td>
              <td><button className={chgButtonClassName(props.seatDetails[seat].seatPriceCode, 'Comp')} onClick={e => props.setSeatDetails({...props.seatDetails, [seat]: {seatID: [seat], seatPrice: 0, seatPriceCode: "Comp"}})}>Comp</button></td>
              </tr>
          )
        })}
        <tr><td></td><td>Apply Credit of:</td><td>-$<input className='donationInput' type="number" min="0" step="1.00" placeholder="0.00" value={props.applyCustomerCredit} onChange={event => {props.setApplyCustomerCredit(event.target.value); props.setCustomerCredit( +initialCustomerCreditValue2 - +event.target.value) }}/></td><td className="donationTagLine" colSpan='4'>to this purchase.</td></tr>
        <tr><td></td><td>Make Donation:</td><td>$<input className='donationInput' type="number" min="0" step="1.00" placeholder="0.00" value={props.donation} onChange={event => props.setDonation(event.target.value)}/></td><td className="donationTagLine" colSpan='4'>...every little bit helps!</td></tr>
        <tr><td></td><td>Total:</td><td>${+totalSeatPrice + +props.donation + +props.applyCustomerCredit}</td><td colSpan='4'></td></tr>
        </tbody>
        </table> 
        
        </div>           
    );
}

export default PurchasePreview