import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import GetEventSeatsInfo from './GetEventSeatsInfo';


function EventSeatInfo1 () {

    const [event, setEvent] = useState({eventName: '',dateTime: ''});
    const [rows, setRows] = useState([]);
    const [reserved, setReserved] = useState([]);
    const [seats, setSeats] = useState([]);
    const [seat, setSeat] = useState({});
    const [SKRoot, setSKRoot] = useState('event-2021-01-01-20-30');
    const [eventSK, setEventSK] = useState(SKRoot +'-seat');
    const [loadEvent, setLoadEvent] = useState(SKRoot + '-details');


        useEffect(() => {
            async function CallGetSeat(){
                let response = await GetEventSeatsInfo("123", eventSK)
                console.log("MARK", response)
                setSeats(response)
            }
            CallGetSeat()
        },[SKRoot])



    return (
    <div className="App">
        <header className="App-header">
        <div className="content">
        <p><button onClick={e => setLoadEvent(SKRoot + '-details')}>Get Event:</button><input type="text" value={SKRoot} onChange={event => setSKRoot(event.target.value)}/></p>
        <h1>{event.eventName}</h1>
        <h1>{event.dateTime}</h1> 
        <table className="tableformat1">   
        <div>
            {seats.map( seat => { 
                return <tr><td>{seat.seatID}</td><td>{seat.seatStatus}</td><td>{seat.dateTime}</td><td>{seat.LastName}, {seat.FirstName}</td></tr>
            })}    
        </div>
        </table>
        </div>
        </header>
    </div>
    );
}

export default EventSeatInfo1