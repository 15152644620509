/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ticket57dynamodb-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1719b814-2e79-40ae-b91c-e75978a29077",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4zO47Z900",
    "aws_user_pools_web_client_id": "3aqjflc0icfrob7un7kv2vrpcm",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "ticket57api",
            "endpoint": "https://hp66up53d6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
