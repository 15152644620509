import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';


function NewEventDetails() {

    const [eventsList, setEventsList] = useState([]);
    const [PK, setPK] = useState('123');
    const [SK, setSK] = useState('');
    const [eventName, setEventName] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [eventSeatPriceAdult, setEventSeatPriceAdult] = useState('');
    const [eventSeatPriceChild, setEventSeatPriceChild] = useState('');
    const [eventSeatPriceSenior, setEventSeatPriceSenior] = useState('');
    const [totalSeats, setTotalSeats] = useState('15');
    const [rows, setRows] = useState([]);

    async function postNewEvent(){
        let myRow = [{"rowId": "C", "rowMap":["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"]},
                     {"rowId": "B", "rowMap":["B1","B2","B3","B4","B5","B6","B7","B8","B9","B10"]},
                     {"rowId": "A", "rowMap":["A1","A2","A3","A4","A5","A6","A7","A8","A9","A10"]}
                    ];
        let myInit = {
            body: { 
                "Action": "putEventDetail",         
                "PK": PK,
                "SK": SK,
                "EventName": eventName,
                "dateTime": dateTime,
                "eventSeatPriceAdult": eventSeatPriceAdult,
                "eventSeatPriceChild": eventSeatPriceChild,
                "eventSeatPriceSenior": eventSeatPriceSenior,
                "totalSeats": totalSeats,
                "rows": myRow
                },
            headers: {} // OPTIONAL
        }
        const responce = await API.post ('ticket57api', '/events', myInit)
        console.log("RESPONSE1", responce)
    }

    async function getEventDetailsByPK(){
        let myInit = {
            body: { 
                "Action": "getEventsDetailByPK",         
                "PK": "123",
                "SK": "event-details" 
                },
            headers: {} // OPTIONAL
        }
        const response = await API.post ('ticket57api', '/events', myInit)
        console.log("RESPONSE1", response)
        setEventsList(response)
    }

    useEffect(() => {
        getEventDetailsByPK()
    },[SK])

    return ( 
    <div className="App">
        <p>Events:</p>
        {eventsList.map( event => {
            return(
                <div>
                <p>SK:{event.SK} EVENTNAME: {event.eventName}</p>
                </div>
            )
        })}

        <table>   
        <tr><td>PK:</td><td><input className='purchasePreviewInput' type="text" value={PK} onChange={event => setPK(event.target.value)}/></td></tr>
        <tr><td>SK:</td><td><input className='purchasePreviewInput' type="text" value={SK} onChange={event => setSK(event.target.value)}/></td></tr>
        <tr><td>EventName:</td><td><input className='purchasePreviewInput' type="text" value={eventName} onChange={event => setEventName(event.target.value)}/></td></tr>
        <tr><td>dateTime:</td><td><input className='purchasePreviewInput' type="text" value={dateTime} onChange={event => setDateTime(event.target.value)}/></td></tr>
        <tr><td>eventSeatPriceAdult:</td><td><input className='purchasePreviewInput' type="text" value={eventSeatPriceAdult} onChange={event => setEventSeatPriceAdult(event.target.value)}/></td></tr>
        <tr><td>eventSeatPriceChild:</td><td><input className='purchasePreviewInput' type="text" value={eventSeatPriceChild} onChange={event => setEventSeatPriceChild(event.target.value)}/></td></tr>
        <tr><td>eventSeatPriceSenior:</td><td><input className='purchasePreviewInput' type="text" value={eventSeatPriceSenior} onChange={event => setEventSeatPriceSenior(event.target.value)}/></td></tr>
        <tr><td>totalSeats:</td><td><input className='purchasePreviewInput' type="text" value={totalSeats} onChange={event => setTotalSeats(event.target.value)}/></td></tr>
        </table>
        <p><button onClick={e => {postNewEvent()}}>Post Event</button></p>

    </div>
    );
}

export default NewEventDetails