import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';

import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function Stripe5(eventName, email, reserved, transactionID, seatDetails, PK, SKRoot) {

    const stripePromise = loadStripe('pk_test_51I7lvpCh85wfodlb68REa63ukOFV0MiENdPMiJJXF34FxfyLyqhkTG8sNPdZVcGf6xU2t1nBv43FeLBugCeBu8g500yTkpfq4b');
    console.log("SKROOT",SKRoot)
    console.log("EVENTNAME",eventName)
    console.log("EMAIL",email)
    console.log("RESERVED",reserved)
    console.log("TRANSACTIONID",transactionID)
    console.log("SEATDETAILSStrip5",seatDetails)
    async function CheckOut() {
        const stripe = await stripePromise;
        let lineItems = [];
        console.log("SEATDETAILSStrip5",seatDetails)
        
        reserved.map( rseat => {
            let fullSeatID = eventName + '-seat-' + rseat
            lineItems.push({'price_data': {'currency':'usd', 'product_data': {'name': fullSeatID + ' ' + seatDetails[rseat].seatPriceCode,},'unit_amount': seatDetails[rseat].seatPrice * 100,},'quantity': 1,})
        })
        console.log("LINEITEMS",lineItems)
        let myInit = {
            body:{
                "Action": "create_checkout_session",
                "EventName": eventName,
                "EmailAddress": email,
                "TransactionID": transactionID,
                "Quantity": 1,
                "LineItems": lineItems,
                "PK": PK,
                "SKRoot": SKRoot,
                "SeatDetails": seatDetails,
            }
        }
        const response = await API.post ('ticket57api', '/stripe', myInit)
        console.log("STRIPERESPONSE",response)
        //const session = await response.json();
        console.log("SESSION",response)
        const result = await stripe.redirectToCheckout({
            sessionId: response,
          });
    }

    CheckOut();

}
export default Stripe5