
import { API } from 'aws-amplify';


function ChangeSeatStatus(seatID, seatStatus, PK, SK, firstName, lastName, sessionUserName) {

    function getFormattedDate(){
        var d = new Date();
        d = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2) + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2) + ":" + ('0' + d.getSeconds()).slice(-2);
        return d;
    }

    async function ChangeStatus() {
        let currDateTime = getFormattedDate();
        let myInit = {
            body:{
                "Action": "putSeatStatus",
                "SeatID": seatID,
                "SeatStatus": seatStatus,
                "PK": PK,
                "SK": SK,
                "DateTime": currDateTime,
                "FirstName": firstName,
                "LastName": lastName,
                "UserID": "123userid",
                "EditByID": sessionUserName
            }
        }
        const response = await API.post ('ticket57api', '/events', myInit)
        return response
    }
    let response = ChangeStatus();
    return response;
}
export default ChangeSeatStatus
