import React from 'react';
import { HashRouter as Router, Link,} from 'react-router-dom';

class MyNavBar extends React.Component {
  render() {
    return (
      <div  className="navRight">
      <Router>
        <div>
          <nav>
            <span className="link1"><Link to="/components/Home">Home</Link></span>
            {/* <span className="link1"><Link to="/components/Events">Events</Link></span>
            <span className="link1"><Link to="/components/Events3">Events3</Link></span>
            <span className="link1"><Link to="/components/EventSeatPrintOut">EventSeatPrintOut</Link></span>
            <span className="link1"><Link to="/components/VideoPlayer">VideoPlayer</Link></span>
            <span className="link1"><Link to="/components/Stripe1">Stripe1</Link></span>
            <span className="link1"><Link to="/components/Stripe2">Stripe2</Link></span> */}
            <span className="link1"><Link to="/components/EventList">EventList</Link></span>
            <span className="link1"><Link to="/components/EventTicketing">EventTicketing</Link></span>
            <span className="link1"><Link to="/components/EventSeatInfo1">EventSeatInfo</Link></span>
            <span className="link1"><Link to="/components/VideoPlayer">VideoPlayer</Link></span>
            <span className="link1"><Link to="/components/NewEventDetails">NewEventDetails</Link></span>
          </nav>
        </div>
      </Router>
      </div>
    );
  }
}

export default MyNavBar;