import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';

function EventList() {

    const [eventsList, setEventsList] = useState([]);
    const [PK, setPK] = useState('123');
    const [SK, setSK] = useState('event-details');

    async function getEventDetailsByPK(){
        let myInit = {
            body: { 
                "Action": "getEventsDetailByPK",         
                "PK": PK,
                "SK": SK 
                },
            headers: {} // OPTIONAL
        }
        const response = await API.post ('ticket57api', '/events', myInit)
        console.log("RESPONSE1", response)
        setEventsList(response)
    }

    useEffect(() => {
        getEventDetailsByPK()
    },[SK])

    return ( 
    <div className="App">
    <div className="content">    
        <p>Events:</p>
        {eventsList.map( event => {
            return(
                <div>
                <p> {event.dateTime} ..... {event.eventName}</p>
                </div>
            )
        })}
    </div>
    </div>
    );
}

export default EventList